import styled from 'styled-components';
import React from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //padding-left: 3px;
  height: 24px;
  width: 24px;
  .SVG_ShareFill{
    fill: #454f63
  }
  
  & svg {
    width: 1.5rem;
   height: 1.5rem;
  }
`;

const IconWebShare = () => {
  return (
    <Container>
     
<svg
   
   height="24px"
   viewBox="0 0 24 24"
   width="24px"
   fill="#FFF"   
   id="svg6">
  
  <defs
     id="defs10" />
  <path
     d="M0 0h24v24H0V0z"
     fill="none"
     id="path2" />
  <path
     d="m 18,16.08 c -0.76,0 -1.44,0.3 -1.96,0.77 L 8.91,12.7 C 8.96,12.47 9,12.24 9,12 9,11.76 8.96,11.53 8.91,11.3 L 15.96,7.19 C 16.5,7.69 17.21,8 18,8 19.66,8 21,6.66 21,5 21,3.34 19.66,2 18,2 c -1.66,0 -3,1.34 -3,3 0,0.24 0.04,0.47 0.09,0.7 L 8.04,9.81 C 7.5,9.31 6.79,9 6,9 4.34,9 3,10.34 3,12 c 0,1.66 1.34,3 3,3 0.79,0 1.5,-0.31 2.04,-0.81 l 7.12,4.16 c -0.05,0.21 -0.08,0.43 -0.08,0.65 0,1.61 1.31,2.92 2.92,2.92 1.61,0 2.92,-1.31 2.92,-2.92 0,-1.61 -1.31,-2.92 -2.92,-2.92 z"
     id="path4" />
</svg>

    </Container>
  );
};

export default IconWebShare;