import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Spinner from "../../modules/Spinner/spinner";


const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 60px - 127px);   
`;

const ErrorMsgButton = styled.button`
    display: flex;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }
`;

interface PageLoaderProps {
    showError?: boolean;
}


const PageLoader = ({showError=false}) => {   
    const {t} = useTranslation("common"); 
    return(
        <LoaderContainer>{showError && <ErrorMsgButton onClick={()=>{open("/", "_self")}}>{t("error.noDataError")}</ErrorMsgButton>}<Spinner/></LoaderContainer>
    )
}

export default PageLoader;