import styled from 'styled-components';
import React from "react";

const Container = styled.div`
  .SVG_ArrowFill{
    fill: #454f63
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconArrowLeft = () => {
  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12.857" viewBox="0 0 15 12.857">
        <path d="M10.17,18.327,4.813,12.97a1.071,1.071,0,0,1,0-1.515L10.17,6.1a1.071,1.071,0,0,1,1.515,1.515L8.157,11.141h10.27a1.071,1.071,0,1,1,0,2.143H8.157l3.528,3.528a1.071,1.071,0,0,1-1.515,1.515Z" 
        transform="translate(-4.499 -5.784)" />
      </svg>
    </Container>
  );
};

export default IconArrowLeft;