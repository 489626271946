import React from "react";
import styled from "styled-components";
import ReadTextButton from "../ReadTextButton/readTextButton";
/* istanbul ignore next */
import ReadTextButtonNative from "../ReadTextButton/readTextButtonNative";


const isNative = () => {
    // @ts-ignore
    if (typeof Capacitor !== "undefined") {
        // @ts-ignore
        if (Capacitor.isNative) {// @ts-ignore
            console.log(Capacitor)
            return true
        }
    }
    return false;
}


const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Heading1 = styled.h1`
    font-weight: 600;
    color: #454F63;
    text-transform: uppercase;
    font-size: 1.875rem;
    line-height: 2rem;
    margin: 0;
    margin-top: 1em;
    hyphens: auto;
`;

const Heading2 = styled.h2`
    font-weight: 700;
    color: var(--colorPrimary);
    font-size: 1.5625rem;
    line-height: 1.9375rem;
    margin: 0;
    margin-top: 1.5em;
    hyphens: auto;
`;

interface HeadingContainerProps {
    stopReaderAudio:boolean;
    setStopReaderAudio:React.Dispatch<React.SetStateAction<boolean>>;
    soundUrl?: string;
    headerType?: "h1" | "h2";
}

const HeadingContainer: React.FC<HeadingContainerProps> = ({ children, soundUrl: soundUrl, stopReaderAudio, setStopReaderAudio, headerType="h1" }) => {
    return (
        <Container>
            {headerType === "h1" && <Heading1 data-testid="headingContainerHeading">{children}</Heading1>}
            {headerType === "h2" && <Heading2 data-testid="headingContainerHeading">{children}</Heading2>}
            {soundUrl && isNative() ?              
                <ReadTextButtonNative
                    soundUrl={soundUrl}
                    stopReaderAudio={stopReaderAudio}
                    setStopReaderAudio={setStopReaderAudio} /> :
                <ReadTextButton
                    soundUrl={soundUrl}
                    stopReaderAudio={stopReaderAudio}
                    setStopReaderAudio={setStopReaderAudio}
                     />}
        </Container>
    );
}

export default HeadingContainer;