import React from "react";
import { useTranslation } from "react-i18next";
import { shortLangCode } from "../../../common/helpers/helpers";
import styled from "styled-components";

const Container = styled.div<LogoContainerProps>`
    display: ${props => props.visible ? "flex" : "none"};
    align-items: center;
    justify-content: center;   
    width: 200px;
    height: 126px;
    
    & .a,.f,.j{
        fill:#fff;
        }
    & .a{
        font-size:46px;
        }
    & .a,.d,.g,.j{
    font-family:National-Extrabold, National;
    font-weight:800;
    }
    & .b{
    letter-spacing:-0.009em;
    }
    & .c{
    letter-spacing:-0.008em;
    }
    & .d,.g{
    fill:#dd994a;
    }
    & .d{
    font-size:45px;
    }
    & .e{letter-spacing:-0.018em;
    }
    & .g,.j{
    font-size:36px;
    }
    & .h{
    letter-spacing:-0.005em;
    }
    & .i{letter-spacing:-0.004em;}
`;

const LogoText = styled.h1`
    font-weight: 800;
    font-size: 3.1875rem;
    line-height: 0.8;
    //letter-spacing: -0.035em;
    color: #FFF;
    margin: 0;
    text-transform: uppercase;
`;

const LogoTextSpan = styled.span`
    color: #dd994a;
`;

interface LogoContainerProps {
    visible: boolean;
}



const TidsromLogo = ({ visible = false }) => {
    const { t, i18n } = useTranslation("common");
    
    return (

        <Container visible={visible}>

            {/*<LogoText>{t("logo.time")}<LogoTextSpan>{t("logo.scape")}</LogoTextSpan></LogoText>*/}

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="180"
                height="114.125"
                viewBox="0 0 180 114.125"
                version="1.1"
                id="svg44">
                <metadata
                    id="metadata48">

                </metadata>
                <defs
                    id="defs4">

                </defs>
                <g
                    transform="translate(0 0)"
                    id="g42">
                    <text
                        className="a"
                        transform="translate(0 41)"
                        id="text12"><tspan
                            className="b"
                            x="0"
                            y="0"
                            id="tspan6">T</tspan><tspan
                                className="c"
                                y="0"
                                id="tspan8">ID</tspan><tspan
                                    y="0"
                                    id="tspan10">S</tspan></text>
                    <text
                        className="d"
                        transform="translate(90.294 41)"
                        id="text20"><tspan
                            className="e"
                            x="0"
                            y="0"
                            id="tspan14">R</tspan><tspan
                                className="b"
                                y="0"
                                id="tspan16">O</tspan><tspan
                                    y="0"
                                    id="tspan18">M</tspan></text>
                    <path
                        className="f"
                        d="M49.128,142.292v-23l-5.9,1.051v-3.2l9.465-1.829v26.977ZM74.5,121.4c-.868-2.515-2.515-3.75-4.847-3.75-4.664,0-7.179,4.572-7.224,13.26h.137c.823-3.841,3.429-5.716,6.858-5.716,5.533,0,8.642,3.841,8.642,8.733,0,4.8-3.247,8.916-9.145,8.916s-9.739-4.344-9.739-13.26c0-10.105,4.984-14.769,10.7-14.769a7.761,7.761,0,0,1,7.727,5.3Zm-5.533,18.747c3.292,0,5.716-2.287,5.716-5.944,0-3.8-2.423-6.081-5.716-6.081-3.155,0-5.669,2.286-5.669,6.081A5.574,5.574,0,0,0,68.972,140.143ZM81.821,128.9c0-7.727,3.886-14.083,10.379-14.083s10.379,6.356,10.379,14.083c0,8.093-3.658,13.946-10.379,13.946S81.821,136.988,81.821,128.9Zm3.52.046c0,6.219,2.561,11.111,6.813,11.111s6.813-4.892,6.813-11.111c0-6.4-2.561-11.111-6.813-11.111S85.341,122.539,85.341,128.941Zm21.444-.046c0-7.727,3.887-14.083,10.379-14.083s10.379,6.356,10.379,14.083c0,8.093-3.658,13.946-10.379,13.946S106.785,136.988,106.785,128.9Zm3.521.046c0,6.219,2.561,11.111,6.813,11.111s6.813-4.892,6.813-11.111c0-6.4-2.561-11.111-6.813-11.111S110.306,122.539,110.306,128.941Z"
                        transform="translate(-42.544 -66.282)"
                        id="path22" />
                    <rect
                        className="f"
                        width="14.814"
                        height="2.515"
                        transform="translate(89.614 61.424)"
                        id="rect24" />
                    <path
                        className="f"
                        d="M284.486,142.292v-23l-5.9,1.051v-3.2l9.465-1.829v26.977Zm12.665-6.036a5.3,5.3,0,0,0,5.487,3.887c6.447,0,7.819-6.722,7.636-13.763h-.183c-.64,4.252-3.566,6.218-7.041,6.218a8.315,8.315,0,0,1-8.733-8.6c0-4.755,3.018-9.191,9.191-9.191,5.99,0,10.014,4.207,10.014,13.031,0,9.923-3.75,15-10.974,15-4.527,0-7.179-1.875-8.459-5.258Zm6.31-18.656c-3.338,0-5.807,2.286-5.807,6.036,0,3.841,2.469,6.219,5.807,6.219,3.109,0,5.716-2.378,5.716-6.219A5.661,5.661,0,0,0,303.462,117.6Zm18.563,24.691v-23l-5.9,1.051v-3.2l9.465-1.829v26.977Zm21.4-7.361h-12.94V132.6l12.848-17.238h3.612V132.1h4.389v2.835h-4.389v7.361h-3.521Zm0-10.2c0-3.475.183-5.486.183-5.486h-.183a38.478,38.478,0,0,1-3.017,4.709l-3.75,5.121a24.426,24.426,0,0,1-2.469,2.972v.183a27.512,27.512,0,0,1,3.7-.137h5.532Z"
                        transform="translate(-171.334 -66.282)"
                        id="path26" />
                    <text
                        className="g"
                        transform="translate(0 102.881)"
                        id="text34"><tspan
                            className="h"
                            x="0"
                            y="0"
                            id="tspan28">T</tspan><tspan
                                className="i"
                                y="0"
                                id="tspan30">IM</tspan><tspan
                                    y="0"
                                    id="tspan32">E</tspan></text>
                    <text
                        className="j"
                        transform="translate(76.257 103.126)"
                        id="text40"><tspan
                            className="i"
                            x="0"
                            y="0"
                            id="tspan36">SCAP</tspan><tspan
                                y="0"
                                id="tspan38">E</tspan></text>
                </g>
            </svg>


        </Container>

    );

};

export default TidsromLogo;