import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { miniMarkdownParser } from "../../../common/helpers/helpers";


const Paragraph = styled.p`
    font-size: 1rem;    
    font-weight: 400;
    color: var(--colorPrimary);
    max-width: 70ch;
    white-space: pre-wrap;
    
    @media only screen and (min-width: 1024px){
        font-size: 1rem;
    }
`;

/*
const Paragraph2 = styled.span`
    font-size: 0.875rem;    
    font-weight: 400;
    color: var(--colorPrimary);
    max-width: 70ch;
    white-space: pre-wrap;
    
    @media only screen and (min-width: 1024px){
        font-size: 1rem;
    }
`;
*/

const ReadMore = styled.span`
    color: var(--colorSecondary);
    cursor: pointer;
`;


interface ParagraphTextProps {
    text: string;
    allTextVisible?: boolean;
    scrollable?: boolean;
    numChars?: number;
}

const NUMCHARS_DEFAULT = 200


const ParagraphText: React.FC<ParagraphTextProps> = ({ text, allTextVisible = false, numChars = NUMCHARS_DEFAULT }) => {
    if (text === undefined || text === "undefined") {       
        text = "";
    }    
    const { t } = useTranslation("common");
    const { width } = useWindowDimensions();
    const isWide: boolean = width >= 1024;
    //console.log("isWide: ", isWide);

    const [showAllText, setShowAllText] = useState(allTextVisible || isWide || text.length < numChars);


    const findDots = (str: string) => {
        const dotPositions = [];
        for (let i = 0; i < str.length; i++) {
            if (str[i] === ".") {
                dotPositions.push(i);
            }
        }
        //console.log("dotPositions: ", dotPositions);
        return dotPositions;
    }

    // Trims the text after the dot nearest to <numChars> characters into text.
    const textLimiter = (text: string): string => {
        if (text.length < numChars) {
            return text;
        }
        const dotPositions = findDots(text);


        const selectedDot = dotPositions.reduce((acc, dotPos): number => {
            const distAcc = Math.abs(numChars - acc);
            const distPos = Math.abs(numChars - dotPos);
            
            if (distPos < distAcc) {
                return dotPos;
            }            
            return acc;

        }, dotPositions[0]);

        //console.log("selectedDot: ", selectedDot);

        if (selectedDot + 1 === text.length) {
            setShowAllText(true);
            return text;
        }

        return text.substring(0, selectedDot + 1);
    }

    
    const addMissingFormatSymbols = (inputStr: string) => {
        const unClosedSymbols = { "*": false, "**": false};
        let currentFormatString = "";        
        //console.log("addMissingFormatSymbols inputStr: ", inputStr);
        for (let chrIdx = 0; chrIdx <= inputStr.length; chrIdx++) {
            if (chrIdx < inputStr.length && inputStr[chrIdx] === "*") {
                //console.log("addMissingFormatSymbols if")
                currentFormatString += "*"                
               
            } else {
                //console.log("addMissingFormatSymbols else") 
                if(currentFormatString){
                    if(currentFormatString === "***"){
                        unClosedSymbols["*"] = !unClosedSymbols["*"];
                        unClosedSymbols["**"] = !unClosedSymbols["**"];
                    }else{
                        unClosedSymbols[currentFormatString] = !unClosedSymbols[currentFormatString];
                    }
                }               
                currentFormatString = "";
            }
        }
        //console.log("unClosedSymbols: ",unClosedSymbols);
        return inputStr + unClosedSymbols["*"] ? "*" : "" + unClosedSymbols["**"] ? "**" : "";
    }

    const markdownToReact = (in_text) => {
        in_text = "**1*2*3*4***";
        if (!in_text.includes("*")) {
            return in_text;
        }
        const parsed = miniMarkdownParser(in_text);
        //console.group("markdownToReact");
        //console.log(parsed);
        const toReact = (parsedIn) => {
            if (typeof parsedIn === "string") {
                return parsedIn;
            }
            //console.log("parsedIn: ", parsedIn)

            if (typeof parsedIn === "object" && !Array.isArray(parsedIn)) {
                return React.createElement(parsedIn.tag, {}, toReact(parsedIn.content));
            }



            return parsedIn.map(el => {
                //console.log("markdownToReact el: ", el)
                if (typeof el === "object") {
                    if (Array.isArray(el)) {
                        return toReact(el);
                    }
                    else {
                        return React.createElement(el.tag, {}, toReact(Array.isArray(el.content) ? el.content/*.reverse()*/ : el.content));
                    }
                }
                else {
                    return el;
                }
            });

        }

        const result = toReact(parsed);
        //console.log("\n\n\nOutput:")
        //console.log(result);
        return result;
    }

    const markdownToHTML = (in_text) => {
        //in_text = "*fk**fk2*";
        if (!in_text.includes("*")) {
            return in_text;
        }
        const parsed = miniMarkdownParser(in_text);
        //console.log(parsed);
        //console.log(parsed.join(""));
        return parsed.join("");
    }

    const handleReadMoreClick = () => {
        setShowAllText(true);
    }

    /**
     * Handles space and enter press for accessability.
     */
    const keyDownHandler = (e) => {
        if (e.key === " " || e.key === "Enter") {
            handleReadMoreClick();
        }
    }

    return (
        <>
            
            {showAllText ?

                <Paragraph
                    key={"test1"}
                    dangerouslySetInnerHTML={{ __html: markdownToHTML(text).toString() }}
                    data-testid="paragraphText" />
                :

                <Paragraph>                    
                    <span
                        key={"test2"}
                        dangerouslySetInnerHTML={{ __html: markdownToHTML(textLimiter(text)).toString() }}                        
                        data-testid="paragraphText" />

                    <ReadMore
                        role="button"
                        onKeyDown={keyDownHandler}
                        tabIndex={0}
                        onClick={handleReadMoreClick}
                        data-testid={"readmorebutton"}>
                        &nbsp;&nbsp;{t("general.readMore")}...
                    </ReadMore >
                </Paragraph>
            }
        </>
    );
}

export default ParagraphText;