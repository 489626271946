import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ShareBox from "../../framework/ShareBox/shareBox";
import IconShare from "../IconShare/iconShare";


const Container = styled.div`
    position: relative;
`;

const SButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    &:hover {
        opacity: 0.7;
        transform: scale(var(--hoverScale));
    }
`;

interface ShareButtonProps {
    //pageUrl: string;
    handleClick: () => void ;
}


const ShareButton: React.FC<ShareButtonProps> = ({ /*pageUrl, */handleClick }) => {
   
    const {t} = useTranslation("common");
    return (
        <Container>
            <SButton
                onClick={handleClick}
                tabIndex={0}
                aria-label={t("shareButton.shareButton")}
                data-testid="sharebtn"
            >
                <IconShare />
            </SButton>

            
        </Container>
    );

}

export default ShareButton;