import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IconArrowLeft from "../IconArrowLeft/iconarrowleft";
import IconStop from "../IconStop/iconStop";
import IconVolumeUp from "../IconVolumeUp/iconVolumeUp";



interface ReadTextContainerProps {
    soundFileIsDefined: boolean;
}

const Container = styled.button<ReadTextContainerProps>`
    display: ${props => props.soundFileIsDefined ? "flex" : "none"};
    align-items: center;
    justify-content: center;
    border: none;
    background: var(--colorBgGray);
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;

    & svg {
        height: 1.125rem;
        width: 1.125rem;
    }

    & :hover {
        transform: scale(var(--hoverScale))
    }

    & :focus{
        outline: none;
        box-shadow: 0 0 0 2px black;
    }
`;

const ReadTextButton = ({ soundUrl, stopReaderAudio, setStopReaderAudio }) => {
    const { t } = useTranslation("common");
    const soundFileIsDefined = !!soundUrl;
    const audioObjectRef: React.MutableRefObject<null | HTMLAudioElement> = useRef(null);

    const [audioStatus, setAudioStatus] /*: [audioStatuses, Dispatch<SetStateAction<audioStatuses>>]*/ = useState("paused")
    const setAudioStatusRef = useRef(setAudioStatus);
    const handleClick = async () => {

        if (audioObjectRef.current.currentTime === 0) {
            //console.log("starting playing")
            await setStopReaderAudio(true); // wait for setState to update.  Prevents overlapping audio
            setStopReaderAudio(false); // Enables audio again.
            audioObjectRef.current.play();
            setAudioStatus("playing");
            //@ts-ignore
            typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
            //@ts-ignore
            window.gtag('event', "reader_audio_click", {
                    'event_category': "user_interaction"                     
            });
            




        } else if (audioObjectRef.current.currentTime > 0) {
            //console.log("STOPPING")
            setAudioStatus("paused");
            audioObjectRef.current.pause();
            audioObjectRef.current.load();
        }
    }

    useEffect(() => {
        if (audioObjectRef.current) {
            setAudioStatusRef.current("paused");
            audioObjectRef.current.pause();
            audioObjectRef.current.src = "";
        }

        audioObjectRef.current = new Audio(soundUrl);
        audioObjectRef.current.addEventListener("canplay", event => {
            //console.log("canPlay");
        });
        audioObjectRef.current.addEventListener("ended", event => {
            //console.log("End of audio-clip");
            setAudioStatusRef.current("paused");
        });
    }, [soundUrl])


    useEffect(() => {

        return (() => {
            //console.log("STOPPING");
            audioObjectRef.current.pause();
            audioObjectRef.current.src = "";
        })
    }, [])

    useEffect(() => {
        if (stopReaderAudio) {
            setAudioStatus("paused");
            audioObjectRef.current.pause();
            audioObjectRef.current.load();
        }
    }, [stopReaderAudio])


    return (
        <Container
            soundFileIsDefined={soundFileIsDefined}
            tabIndex={0}
            aria-label={t("readTextButton.readText")}
            onClick={handleClick}
        >
            {audioStatus === "paused" ? <IconVolumeUp /> : <IconStop />}

        </Container>
    );
}

export default ReadTextButton;