import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
`;

const AnimatedChar = styled.div<AnimatedCharProps>`
    
    @keyframes test {
        0% {opacity:0.0}        
        100% {opacity:1.0}
    }
    
    opacity: 0.0;
    animation-name: test;
    animation-duration: 1.25s;
    animation-delay: ${props => props.animationDelay + "s"};
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-direction: alternate;
    padding: 2px;
    font-size: 3.125rem;
    
    
`

interface AnimatedCharProps{
    animationDelay: number;
}

const Spinner = () =>{
    const text = ".....";
    return(
        <Container>
            <AnimatedChar animationDelay={0}>{text[0]}</AnimatedChar>
            <AnimatedChar animationDelay={1}>{text[1]}</AnimatedChar>
            <AnimatedChar animationDelay={2}>{text[2]}</AnimatedChar>
            
            
            
            
        </Container>
    )
}

export default Spinner;
