import i18next from "i18next";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { shortLangCode } from "../../../common/helpers/helpers";

const LangButton = styled.button<LanguageButtonProps>` 
    
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.isSmallWhite ? "#FFF" : "#000"};
    background: none;    
    padding: 1px 6px;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.1875rem;
    color: ${props => props.isSmallWhite ? "#FFF" : "#000"};
    font-weight: 400;   
    text-transform: uppercase;  
    width: 2em;
    height: 1.3125em;
    cursor: pointer;
    user-select: none;

    &:hover{
        transform: scale(var(--hoverScale));
        opacity: 0.7;
    }
    

    @media only screen and (min-width: 1024px){
        color: #000;
        border-color: #000;        
    } 


    
`;

const LanguageButtonTextReaderLabel = styled.div`
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
`;

interface LanguageButtonProps {
    isSmallWhite?: boolean;
}

const LanguageButton = ({ isSmallWhite = false }) => {
    const { t, i18n } = useTranslation("common");

    const [choosenLanguage, setChoosenLanguage] = useState("no");
    const otherLanguage = choosenLanguage === "no" ? "en" : "no";
    /*console.group("Load")
    console.log("choosenLanguage: ", choosenLanguage);
    console.log("otherLanguage: ",otherLanguage);
    console.log("i18next.language: ",i18n.language);
    console.groupEnd();*/

    const toggleLanguage = () => {
        i18next.changeLanguage(otherLanguage);
        //@ts-ignore
        typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
            //@ts-ignore
            window.gtag('event', `changed_language_${otherLanguage}`, {
                    'event_category': "user_interaction"                     
            });
        //.then(()=>setChoosenLanguage(i18n.language));
        /*console.group("TOGGLE")
        console.log("choosenLanguage: ", choosenLanguage);
        console.log("otherLanguage: ",otherLanguage);
        console.log("i18next.language: ",i18n.language);
        console.groupEnd();*/

    }

    useEffect(() => {
        //console.log("LANGUAGE CHANGED USEEFECT");
        setChoosenLanguage(shortLangCode(i18n.language));
    },
        [i18n.language])

    return (
        <>

            
            <LangButton
                tabIndex={0}
                isSmallWhite={isSmallWhite}
                onClick={toggleLanguage}               
                aria-label={`${t("languageButton.buttonAriaLabel_1")} ${t("languageButton." + otherLanguage + "_1")}. ${t("languageButton.buttonAriaLabel_2")} ${t("languageButton." + otherLanguage + "_2")}`}
            >{otherLanguage}</LangButton>
        </>
    );
}

export default LanguageButton;