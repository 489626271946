import React from "react";

import styled from "styled-components";
import Title from "../framework/Title/title";
import Footer from "../framework/Footer/footer";
import LanguageButton from "../modules/LanguageButton/languageButton";
import NavBar from "../framework/NavBar/navBar";
import { title } from "process";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { shortLangCode } from "../../common/helpers/helpers";
import shareImage from "../../images/bg/bgimg_auto.jpg";
import { Settings } from "../../config/Settings";
import TextSizeButton from "../modules/TextSizeButton/textSizeButton";
import OldBrowserWarning from "../modules/OldBrowserWarning/oldBrowserWarning";
import * as staticShareImgUrlJson from "../../images/bg/bgimg_auto.json";


const staticShareImgUrl = staticShareImgUrlJson.url;
const descriptionFallback = "TidsRom 1600-1914 - Borgerskap og embetsstand. En utstilling hos Norsk Folkemuseum";

const isNativeIos = () => {
    // @ts-ignore
    if (typeof Capacitor !== "undefined") {
        // @ts-ignore
        if (Capacitor.getPlatform() === "ios" && Capacitor.isNative) {
            return true
        }
    }
    return false;
}


const PageWrapperOuterContainer = styled.div`
    background-color: #000;
    display: flex;
    justify-content: center;
`;

const PageWrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;       
    min-height: 100vh;
    width: 100%;
    max-width: 100vw;
    background-color: #FFF;

    @media only screen and (min-width: 1024px){
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-areas: "title main";
        grid-template-rows: 100vh;
        max-width: 1600px;
        //flex-direction: row;
    }
`;

const MainAndFooterContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    flex: 1;   
    overflow-x: hidden;

    @media only screen and(min-width: 1024px){
        max-width: 700px;
        grid-area: main;
    } 
`;


const LangButtonContainer = styled.div<LanguageButtonContainerProps>`     
    display: flex;
    position: relative;    
    align-self: flex-end;
    margin-right: var(--marginLeftLg);
    margin-top: var(--marginLeftLg);
    align-items: center; 

    & button {
        :not(:last-of-type){
            margin-right: 1em;
        }
    }
    
    @media only screen and (max-width: 1023px){       
        
        position: absolute;        
        right: 31px;
        top: calc(31px + ${isNativeIos() ? "23px" : "0px"});
        margin: 0;        
    } 
    
`;



interface LanguageButtonContainerProps {
    isHomePage?: boolean;
}



interface PageWrapperProps {
    children: React.ReactNode;
    backObj?: { url: string, title: string };
    isHomePage?: boolean;
    pageTitle?: string;
    pageUrl?: URL;
    description?: string;
    image?: string;

}

const PageWrapper: React.FC<PageWrapperProps> = ({
    children,
    backObj = { url: "/", title: "general.home" },
    isHomePage,
    pageTitle = "Tidsrom 1600-1914",
    pageUrl = new URL(Settings.PAGE_URL), /* CHANGE TO WWW FOR FINAL */
    description = descriptionFallback,
    image = staticShareImgUrl,
}) => {
    const { t, i18n } = useTranslation("common");
    
    if (typeof document !== "undefined") {
        document.documentElement.lang = i18n.language;
    }

    const startPageUrl = Settings.PAGE_URL;

    const staticTitle = "Tidsrom 1600-1914"

    //console.log("shareImage: ", startPageUrl + shareImage.toString());
    /*const shareImageUrl = (()=>{
        let imgUrl;
        try{
            imgUrl = startPageUrl + shareImage.toString();
        }
        catch(err){
            imgUrl = "img.png";
        }
        finally{
            return imgUrl;
        }

    
    })()*/
    const shareImageUrl = startPageUrl + shareImage.toString();
    //console.log("\nPAGE_URL: ", Settings.PAGE_URL, "\n");
    //console.log("shareImage: ", shareImage);
    return (
        <PageWrapperOuterContainer
            lang={shortLangCode(i18n.language)}
        >

            <PageWrapperContainer
                lang={shortLangCode(i18n.language)}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{/*staticTitle*/ isHomePage ? "Tidsrom 1600-1914" : `${pageTitle}`}</title>
                    <meta name="description" content={description || descriptionFallback}></meta>
                    <meta property="og:title" content={isHomePage ? "Tidsrom 1600-1914" : `${pageTitle}`} />
                    <meta property="og:description" content={description || descriptionFallback} />
                    <meta property="og:site_name" content="Tidsrom" />
                    <meta property="og:url" content={`${pageUrl}`} /* TODO ADD SPECIFIC URL  */ />
                    <meta property="og:type" content="article" />
                    <meta property="og:locale" content="nb_NO" />
                    <meta property="og:locale:alternate" content="en_US" />
                    <meta property="og:site_name" content="Tidsrom" />
                    <meta property="og:image" content={image} />
                    <meta property="fb:app_id" content="1513815608824651" /* TODO CHANGE TO REAL APP-ID*/ />
                    <meta property="image" content={image} />
                    <meta property="title" content={isHomePage ? "Tidsrom 1600-1914" : `${pageTitle}`} />
                    <meta property="type" content="article" />

                    {/* TWITTER */}
                    <meta name="twitter:title" content={isHomePage ? "Tidsrom 1600-1914" : `${pageTitle}`} />
                    <meta name="twitter:description" content={description || descriptionFallback} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:creator" content="@Folkemuseet" />





                </Helmet>

                <Title isHomePage={isHomePage} />
                <MainAndFooterContainer>
                    {isHomePage ?
                        <LangButtonContainer isHomePage={isHomePage}>
                            <TextSizeButton isWhite />
                            <LanguageButton isSmallWhite={isHomePage} />
                        </LangButtonContainer>
                        :
                        <NavBar
                            backObj={backObj}
                            pageUrl={pageUrl}
                            title={isHomePage ? "Tidsrom 1600-1914" : `${pageTitle}`}
                            description={description || descriptionFallback} />
                    }
                    <main>
                        {children}
                    </main>
                    <Footer />
                </MainAndFooterContainer>
                <OldBrowserWarning />
            </PageWrapperContainer>
        </PageWrapperOuterContainer>
    );
}

export default PageWrapper;