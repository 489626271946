import React, { useContext, useEffect, useRef, useState } from "react";
import TidsromLogo from '../../modules/TidsromLogo/tidsromlogo'
import styled from "styled-components";
import { useTranslation } from "react-i18next";
//import bgImg from "../../../images/bg/bgimg2x.jpg";
import { navigate } from "gatsby";
import { shortLangCode } from "../../../common/helpers/helpers";
import { Store } from "../../Store/store";


const TitleOuterContainer = styled.div<TitleOuterContainerProps>`    
    background-color: #36352F;    
    display: ${props => props.isHomePage ? "flex" : "none"};
    flex-direction: column;  
    z-index: 0;   
    position: relative;
    @media only screen and (min-width: 1024px){
        display: flex;
        flex: 1;
        grid-area: title;
        width: 100%;
    }
`;

const TitleContainer = styled.div<TitleContainerProps>`    
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;    
    justify-content: center;    
    background-position: 22% 16%;
    background-image: url(${props => props.bgImg});    
    background-repeat: no-repeat;  
    background-size: cover;     
    height: 420px;    
    z-index: 1;

    @media only screen and (min-width: 1024px){
        //min-width: 499px;
        //max-width: 100%;
        position: sticky;
        top: 0;
        height: 100vh;
        //max-height: 834px;        
        //background-attachment: fixed;
        //width: 50%;
        
    }     
`;

const InnerContainer = styled.div`
    //position: absolute;
    //top: 54%;
    //transform: translateY(-50%);
    //margin: auto;
    display: flex;
    flex-direction: column;
    z-index: 4;
    @media only screen and (min-width: 1024px){
        //top: 60%;   
        transform: translateY(15vh);     
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    cursor: pointer;
`;

const ReadMoreButton = styled.button`
    border: 1px solid #FFF;
    background: none;
    padding: 7.4px 15px 7.6px;
    font-size: 0.875rem;
    letter-spacing: 0.49px;
    color: #FFF;
    align-self: center;
    font-weight: 400;  
    justify-self: flex-end;
    margin-top: 70px;   
    cursor: pointer;

    &:hover{        
        opacity: 0.7;
    }    

    @media only screen and (min-width: 1024px){
        margin-top: 85px;   
        //margin-top: 10%;
    }
`;

interface TitleContainerProps {
    bgImg: string;
}

interface TitleOuterContainerProps {
    isHomePage?: boolean;
}

interface TitleProps {
    isHomePage?: boolean;
}


const Title: React.FC<TitleProps> = ({ isHomePage }) => {

    const { t, i18n } = useTranslation("common");

    const [currentLanguage, setCurrentLanguage] = useState(shortLangCode(i18n.language));
    const setCurrentLanguageRef = useRef(setCurrentLanguage);    
    const currentLanguageRef = useRef(currentLanguage);
    const { state }: { state: any } = useContext(Store);
    //@ts-ignore
    const [bgImg, setBgImg] = useState(state.data?.settings?.content?.image?.media?.mediaUrl ?? "");
    const setBgImgRef = useRef(setBgImg);
    const bgImgRef = useRef(bgImg);

    const keyDownHandler = (e) => {

        //e.preventDefault();
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            navigate("/");
        }
    }

    useEffect(() => {

        const _lang = shortLangCode(/*i18nRef.current*/currentLanguageRef.current);
        //console.log("\n\n\n_lang:", _lang)        

        setCurrentLanguageRef.current(_lang);
    }, [])

    useEffect(() => {
        if(!state.data.settings){return}
        if (!Object.keys(state.data.settings).length) { return }
        if (bgImgRef.current) {
            return;
        }
        //console.log(state.data.settings)
        const _bgImg = state.data.settings.content.image.media.mediaUrl;
        setBgImgRef.current(_bgImg);
        //console.log("_bgImg: ", _bgImg);
    }, [state.data.settings])

    return (
        <TitleOuterContainer
            role="banner"
            isHomePage={isHomePage}>
            <TitleContainer bgImg={bgImg}>
                <InnerContainer>
                    <LogoContainer
                        onClick={() => { navigate("/") }}
                        onKeyDown={keyDownHandler}
                        /*tabIndex={0}*/
                        role="button">                        
                        <TidsromLogo visible />
                    </LogoContainer>


                    {false && <ReadMoreButton
                        tabIndex={0}
                        data-testid="readmore"
                        onClick={() => {
                            window.open("https://norskfolkemuseum.no/tidsrom", "_blank", "noopener")
                        }}
                    >{t("title.readMore")}</ReadMoreButton>}
                </InnerContainer>
            </TitleContainer>
        </TitleOuterContainer>
    );
};

export default Title;