import styled from 'styled-components';
import React from "react";

const Container = styled.div`
  .SVG_ShareFill{
    fill: #454f63
  }
  display: flex;
  align-items: center;
  & svg {
    width: 16px;
   height: 16px;
  }
`;

const IconFormatSize = () => {
  return (
    <Container>
      <svg
       xmlns="http://www.w3.org/2000/svg" 
       height="24px" 
       viewBox="0 0 24 24" 
       width="24px" 
       fill="#000000">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M9 4v3h5v12h3V7h5V4H9zm-6 8h3v7h3v-7h3V9H3v3z"/>
        </svg>
    </Container>
  );
};

export default IconFormatSize;