import styled from 'styled-components';
import React from "react";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    
`;

const IconStop = () => {
    return (
        <Container>
            <svg xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
            >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M6 6h12v12H6z" fill="#454F63" />
            </svg>
        </Container>
    )
}

export default IconStop;