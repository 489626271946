import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import NfLogo from "../../modules/NfLogo/nflogo";
import NfLogoPng from "../../../images/icons/nf_logo.png";

const FooterContainer = styled.footer`
    position: relative;
    background-color: #e7e7e7;  //#F0F0F0; original color not visible on some displays.
    //background-color: gray;
    display: flex;
    flex-direction: column;    
    padding: 30px var(--marginLeftSm) 22px;  
    min-height: 8em;
    margin-top: auto; // force footer to the bottom of the page.

     @media only screen and (min-width: 1024px){
        padding-left: var(--marginLeftLg);
    } 
    
`;

const FooterHeading = styled.h2`
    position: absolute;
    top: calc(54.3px + 22px - 2.3em); // logo height + logo top - font-size
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.3125rem;
    margin: 0;
`;

const FooterSubHeadingLink = styled.a`
    position: absolute;
    left: var(--marginLeftSm); 
    top: calc(54.3px + 22px - 1em); // logo height + logo top - font-size
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.0625rem;
    margin: 0;
    text-decoration: none;
    color: #000;
    
    @media only screen and (min-width: 1024px){
        left: var(--marginLeftLg);
    } 

    
    &:hover{
        text-decoration: underline;
    }

`;

const List = styled.ul`
    position: absolute;
    bottom: 20px;
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-bottom: 0px;
`;

const ListItem = styled.li`    
    &:not(:last-of-type) {
        margin-right: 17px;
    }
`;


const Link = styled.a`
    text-decoration: none;
    color: var(--colorSecondary);
    font-size: 0.75rem;    

    &:hover{
        text-decoration: underline;
    }
`;

const LogoContainer = styled.div`
    position: absolute;
    right: 45px;
    top: 22px;

    @media only screen and (min-width: 1024px){
        right: 45px;
    }
`;

const LogoImg = styled.img`
    width: 42px;
    height: 42px;
    object-fit: contain;
`;


const Triangle = styled.div`
    position: absolute;
    right: 25px;
    top: -30px;
    //transform: translateX(50%);
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid #e7e7e7;

    @media only screen and (min-width: 1024px){
        right: 25px;
    }
    
`;

// TODO REMOVE THIS
const isNativeIos = () =>{
    // @ts-ignore
    if (typeof Capacitor !== "undefined"){
       // @ts-ignore
        if (Capacitor.getPlatform() === "ios" && Capacitor.isNative) {
           return true
       }       
    }
    return false;        
}


const Footer = () => {

    const {t} = useTranslation("common");

    return (
        <FooterContainer>
            <Triangle/>
            {/* @ts-ignore  TODO REMOVE THIS*/
            typeof Capacitor !== "undefined" ? <><p>{`platform: ${Capacitor.getPlatform()}`}</p><p>{`isNative: ${Capacitor.isNative}`}</p></>:""
            }
            <LogoContainer>{/*<LogoImg src={NfLogoPng}/>*/}{<NfLogo/>}</LogoContainer>
            <FooterHeading>{t("footer.title")}</FooterHeading>
            <FooterSubHeadingLink 
            href={"https://norskfolkemuseum.no/tidsrom"} rel="noopener" target={"_blank"}>{t("footer.readMore")}</FooterSubHeadingLink>
            <List>
                
                <ListItem>
                    <Link href={"https://norskfolkemuseum.no/cookies"} rel="noopener" target={"_blank"}>{t("footer.cookiesPrivacy")}</Link>
                </ListItem>
            </List>
        </FooterContainer >
    );
}

export default Footer;