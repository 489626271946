import styled from 'styled-components';
import React from "react";

const Container = styled.div`
  .SVG_ShareFill{
    fill: #454f63
  }
  display: flex;
  align-items: center;
  & svg {
    width: 16px;
   height: 16px;
  }
`;

const IconShare = () => {
  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg"
        width="14.5"
        height="14.498"
        viewBox="0 0 14.5 14.498">
        <defs></defs>
        <g transform="translate(0.25 0.25)">
          <path className="SVG_ShareFill"
            d="M1,8.576c.072-.064.137-.135.205-.2l6.1-6.141c.087-.087.178-.17.333-.318v2.82c0,.436.22.707.555.7s.552-.274.553-.71q0-1.745,0-3.49V0C7.082,0,5.47,0,3.856.005a.535.535,0,0,0-.391.166.542.542,0,0,0-.15.4.539.539,0,0,0,.559.546c.494.019.987.007,1.481.008H6.79c-.145.158-.217.246-.3.329Q5.011,2.945,3.528,4.434,1.866,6.106.21,7.778a.549.549,0,0,0-.061.806A.616.616,0,0,0,1,8.576Z"
            transform="translate(5.25)" />
          <path className="SVG_ShareFill" d="M13.984,8.831a.538.538,0,0,0-.652-.536.574.574,0,0,0-.442.643q.006,1.573,0,3.146c0,.6-.2.8-.786.8H1.888c-.578,0-.774-.2-.774-.771V1.9c0-.6.192-.786.8-.786H4.484c.489,0,.759-.207.753-.566C5.23.2,4.961,0,4.5,0H1.925A1.756,1.756,0,0,0,0,1.93V12.083A1.75,1.75,0,0,0,1.9,14H6.979c1.738,0,3.477.005,5.218,0a1.687,1.687,0,0,0,1.781-1.59C14.017,11.214,14,10.022,13.984,8.831Z" /></g>
      </svg>
    </Container>
  );
};

export default IconShare;