import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Store } from "../../Store/store";
import IconFormatSize from "../IconFormatSize/iconFormatSize";
import IconTextFields from "../IconTextFields/iconTextFields";

const TextButton = styled.button<TextButtonProps>`
    border: none;
    padding: .32em;
    height: 30px;
    background: none;
    display: flex;
    align-items: center;
    justify-self: flex-start;
    //line-height: 1.375rem;
    line-height: 24px;
    cursor: pointer;  

    svg{
        fill: ${props => props.isWhite  ? "#FFF" : "#454f63"};        
    }

    @media only screen and (min-width: 1024px){
        svg{
        fill: #454f63;        
        }
    }

    & svg{
        height: 15px;
        
    }
    &:hover {
        opacity: 0.7;
        transform: scale(var(--hoverScale));
    }
`;


interface TextButtonProps {
    isWhite?: boolean;
}




const TextSizeButton:React.FC<TextButtonProps> = ({isWhite=false}) => {

    const {state, dispatch} = useContext(Store);
    const {t} = useTranslation("common");

    const textSizeChangeHandler = () => {
        //const root = document.querySelector(":root");
        //root.style.fontSize = "25px";
        //@ts-ignore
        typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
            //@ts-ignore
            window.gtag('event', "changed_text_size", {
                    'event_category': "user_interaction"                     
            });
        dispatch({type: "SET_DATA_LARGEFONT",
    payload: !state.data.largeFont})
    }

    return (
        <TextButton
            onClick={textSizeChangeHandler}
            isWhite={isWhite}
            aria-label={state.data.largeFont ? t("textSizeButton.smallerText") : t("textSizeButton.largerText")}
        >{state.data.largeFont ? <IconTextFields/> : <IconFormatSize/>}</TextButton>
    );
}

export default TextSizeButton;