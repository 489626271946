import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const IMAGESIZES = [
    { width: 57, height: 57, crop: true, square: true, dimensions: "57x57-crop" },
    { width: 145, height: 105, crop: false, square: false, dimensions: "145x105" },
    { width: 167, height: 167, crop: false, square: true, dimensions: "167x167" },
    { width: 174, height: 156, crop: true, square: false, dimensions: "174x156-crop" },
    { width: 250, height: 250, crop: false, square: true, dimensions: "250x250" },
    { width: 400, height: 400, crop: false, square: true, dimensions: "400x400" },
    { width: 600, height: 380, crop: false, square: false, dimensions: "600x380" },
    { width: 600, height: 600, crop: false, square: true, dimensions: "600x600" },
    { width: 720, height: 480, crop: false, square: false, dimensions: "720x480" },
    { width: 800, height: 800, crop: false, square: true, dimensions: "800x800" },
    { width: 1200, height: 1200, crop: false, square: true, dimensions: "1200x1200" },
    { width: 1400, height: 1400, crop: false, square: false, dimensions: "max" }, /* width and height could be larger or smaller */
];

const ZINDEX_START = 1;

const Container = styled.div`
    display: flex;
    position: relative;  
    align-items: center;  
    justify-content: center;
    width: 100%;
    height: 100%;

`;

const ImageContainer = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;    
    
`;


const ImageComp = styled.img<ImageProps>`    
    width: 100%;
    height:  100%;
    object-fit: ${props => props.fitCover ? "cover" : "contain"}; 
    opacity: ${props =>props.loaded || props.singleImage ? "1.0" :"0.0"};
    transition: opacity 0.1s ease-in;
    border-radius: ${props =>props.borderRadius};
`;

interface ImageProps {
    singleImage: boolean;
    fitCover: boolean;
    loaded?: boolean; 
    borderRadius?: string;
    
}

interface ImageLoaderProps {
    imgUrl: string;
    minSize?: number;
    maxSize?: number;
    cropAccepted?: boolean;
    squareRequired?: boolean;
    
    /**
     * maximum number of images to load
     */
    maxImages?: boolean;
    getNLargest?: boolean;
    alt?: string;
    fitCover?: boolean;
    borderRadius?: string;
}


/**
 * TODO: Implementer forskjellige bildekilder. Feks bruk @1.type @2.type @3.type på lokale bilder.
 * 
 * 
 */

//:React.FC<ImageLoaderProps>
const ImageLoader = ({ imgUrl, alt="", minSize = 57, maxSize = 1400, cropAccepted = false, squareRequired = false, maxImages = true, fitCover = false, borderRadius = "0px" /*getNLargest = false*/ }) => {
    const [largestImageLoaded, setLargestImageLoaded] = useState(0);
    //const [imagesLoaded, setImagesLoaded] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const setImagesListRef = useRef(setImagesList);
    
    useEffect(()=>{       

        const isDigitaltMuseum = () =>{
            if (imgUrl.match(/\.dimu\./)){
                return true;
            }
            //console.log("Image is not from digitaltmuseum");
            return false;
        }

        const generateImageList = () => {
            const images = [];            

            
            for (let img of IMAGESIZES/*(getNLargest ? [...IMAGESIZES].reverse() : IMAGESIZES)*/) {
                
                if (img.width > maxSize) {                    
                    break;
                }                
                
                if (img.width < minSize) {                    
                    continue;
                }
                if (img.crop && !cropAccepted) {
                    continue;
                }
                if (!img.square && squareRequired) {
                    continue;
                }
                images.push(`${imgUrl}&dimension=${img.dimensions}`);
            }
            return /*getNLargest ? images.reverse() :*/ images;
        }
        const _imagesList = isDigitaltMuseum() ? generateImageList() : [imgUrl]; // TMP fix        
        
        const selectImages = () =>{  
            if(_imagesList.length < 2){
                return _imagesList;
            }         
            if(!maxImages || _imagesList.length < 3){
                return [_imagesList[0], _imagesList[_imagesList.length - 1]];
            }
            return [_imagesList[0], _imagesList[Math.floor(_imagesList.length / 2)], _imagesList[_imagesList.length - 1]];
            
        } 
        
        setImagesListRef.current(selectImages());        

    },[]);

    const handleLoad = (idx) =>{       

        if (idx > largestImageLoaded) setLargestImageLoaded(idx);
    }

    return (
        <Container>
            {imagesList.map((el, idx) =>
                
                    <ImageContainer
                        key={el + idx}
                        style={{zIndex:idx + ZINDEX_START}}>
                        <ImageComp
                            loading="lazy"                           
                            src={el}
                            onLoad={()=>{handleLoad(idx)}} 
                            singleImage={imagesList.length < 2}
                            loaded={idx <= largestImageLoaded} 
                            alt={idx === 0 ? alt : ""}   
                            fitCover={fitCover}  
                            borderRadius={borderRadius}                                                
                        />
                    </ImageContainer>               
            )
            }

        </Container >
    )
}

export default ImageLoader;

/*
57x57-crop

145x105

167x167

174x156-crop

250x250

400x400

600x380

600x600

720x480

800x800

1200x1200

https://mm.dimu.org/image/<identifier>?dimension=<dim>

Eksempler:
https://mm.dimu.org/image/022uMWqFHQUw?dimension=145x105

https://mm.dimu.org/image/022uMWqFHQUw?dimension=250x250

https://mm.dimu.org/image/022uMWqFHQUw?dimension=174x156-crop


*/