/* istanbul ignore file */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IconArrowLeft from "../IconArrowLeft/iconarrowleft";
import IconStop from "../IconStop/iconStop";
import IconVolumeUp from "../IconVolumeUp/iconVolumeUp";
/* istanbul ignore next */
import { Media } from "@ionic-native/media";


interface ReadTextContainerProps {
    soundFileIsDefined: boolean;
}

const Container = styled.button<ReadTextContainerProps>`
    display: ${props => props.soundFileIsDefined ? "flex" : "none"};
    align-items: center;
    justify-content: center;
    border: none;
    background: var(--colorBgGray);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;

    & :focus{
        outline: none;
        box-shadow: 0 0 0 2px black;
    }
`;

const ReadTextButtonNative = ({ soundUrl: soundUrl, stopReaderAudio, setStopReaderAudio  }) => {
    //const NativeSound = Media.create(soundUrl);
    const { t } = useTranslation("common");
    const soundFileIsDefined = soundUrl !== undefined;
    const audioObjectRef = useRef(undefined);
    const statusCode = useRef(-1);
    //const loadSoundRef = useRef(true);

    const [audioStatus, setAudioStatus] /*: [audioStatuses, Dispatch<SetStateAction<audioStatuses>>]*/ = useState("paused")
    const setAudioStatusRef = useRef(setAudioStatus);
    const handleClick = () => {
        //console.log("\nstatusCode.current: ", statusCode.current, "\n")

        if (audioStatus === "paused"/*[-1, 1, 3, 4].includes(statusCode.current)*/ /*audioObjectRef.current.currentTime === 0*/) {
            //console.log("starting playing")

            /*if(loadSoundRef.current){
                // stop loading and set volume
                audioObjectRef.current.stop();
                audioObjectRef.current.setVolume(1);
                loadSoundRef.current = false;
                console.log("Starting sound after preload");
            }*/
            audioObjectRef.current.play();
            setAudioStatus("playing");
            setStopReaderAudio(false);


        } else if (audioStatus === "playing") {
            //console.log("STOPPING")
            setAudioStatus("paused");
            audioObjectRef.current.stop();
            //audioObjectRef.current.load();
        }
    }
    /*
    useEffect(() => {
        audioObjectRef.current.onStatusUpdate.subscribe(status => {
            console.log("\n\naudioObjectRef.current.onStatusUpdate.subscribe: ", status,"\n\n");
            statusCode.current = status});
            */
    //audioObjectRef.current = new Audio(soundUrl);
    /*audioObjectRef.current.addEventListener("canplay", event => {
        console.log("canPlay");
    });*/
    /*
}, [soundUrl])
*/


    useEffect(() => {

        if (audioObjectRef.current) {
            audioObjectRef.current.stop();
            audioObjectRef.current.release();
            statusCode.current = -1;
            setAudioStatusRef.current("paused");
        }
        audioObjectRef.current = Media.create(soundUrl);

        audioObjectRef.current.onStatusUpdate.subscribe(status => {
            //console.log("\n\naudioObjectRef.current.onStatusUpdate.subscribe: "+ status + "\n\n");
            statusCode.current = status
            if (status === 4){
                setAudioStatusRef.current("paused");
            }
        });

    }, [soundUrl])



    useEffect(() => {
        //console.log("Preloading sound");


        
        return (() => {
            //console.log("STOPPING");
            audioObjectRef.current.stop();
            audioObjectRef.current.release();
        })
    }, [])

    useEffect(()=>{
        if(stopReaderAudio){
            audioObjectRef.current.stop();
            setAudioStatusRef.current("paused");
            
        }
    },[stopReaderAudio])




    return (
        <Container
            soundFileIsDefined={soundFileIsDefined}
            tabIndex={0}
            aria-label={t("readTextButton.readText")}
            onClick={handleClick}
        >
            {/*console.log("\n\nNATIVE SOUND PLAYER\n\n")*/}
            {audioStatus === "paused" ? <IconVolumeUp /> : <IconStop />}
            

        </Container>
    );
}

export default ReadTextButtonNative;