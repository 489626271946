//import Link from "next/link";
import { Link, navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IconArrowLeft from "../../modules/IconArrowLeft/iconarrowleft";
import IconShare from "../../modules/IconShare/iconShare";
import LanguageButton from "../../modules/LanguageButton/languageButton";
import ShareButton from "../../modules/ShareButton/shareButton";
import ShareBox from "../ShareBox/shareBox";
import ClickAwayListener from 'react-click-away-listener';
import TextSizeButton from "../../modules/TextSizeButton/textSizeButton";


const isNativeIos = () => {
    // @ts-ignore
    if (typeof Capacitor !== "undefined") {
        // @ts-ignore
        if (Capacitor.getPlatform() === "ios" && Capacitor.isNative) {
            return true
        }
    }
    return false;
}

const Container = styled.nav`
    display: flex;
    position: relative;
    //width: 90%; //FIX THIS
    justify-content: space-between;
    align-items: center;
    height: calc(50px + ${isNativeIos() ? "44px" : "0px"});    
    margin-left: var(--marginLeftSm);
    margin-right: var(--marginLeftSm);
    
    padding-top: ${isNativeIos() ? "44px" : "0px"};
    @media only screen and (min-width: 1024px){
        margin-left: var(--marginLeftLg);
        margin-right: var(--marginLeftLg);
        margin-top: var(--marginTopNavLg);
        height: 40px;
    }
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding-bottom: 1px;
`;

const BackButton = styled.button`
    border: none;
    padding: 0;
    height: 30px;
    background: none;
    display: flex;
    align-items: center;
    justify-self: flex-start;
    //line-height: 1.375rem;
    line-height: 24px;
    cursor: pointer;  
    
    
    & svg{
        height: 15px;
        
    }
    &:hover {
        opacity: 0.7;
        transform: scale(var(--hoverScale));
    }
`;


const BackButtonText = styled.p`
    display: flex;    
    font-size: 16px;
    letter-spacing: -0.53px;
    line-height: 24px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;    
    margin-top: 0px;
    margin-bottom: 0px;
`;

const AppTitle = styled.h1`
    justify-self: center;    
    font-size: 20px;//1.25rem;
    line-height: 24px; //1.5rem;
    font-weight: 800;
    cursor: pointer;
    @media only screen and (min-width: 1024px){
        visibility: hidden;
    }
`;

const BtnContainer = styled.div`
    display: flex;
    align-items: center;    
    width: 112px;
    justify-content: space-between;
`;

const ClickAwayListenerCustom = styled(ClickAwayListener)`
    position: absolute;
    right: 0;
`;


const ClickAwayInnerContainer = styled.div`
    position: relative;

`;




interface NavBarProps {
    backObj: { url: string, title: string };
    pageUrl: URL;
    title:string;
    description: string;
}

const NavBar: React.FC<NavBarProps> = ({ backObj, pageUrl, title, description }) => {
    const { t } = useTranslation("common");
    const [shareOpen, setShareOpen] = useState(false);
    const setShareOpenRef = useRef(setShareOpen);    
    const veryRecentlyClickedAwayRef = useRef(false);
    const keyDownHandler = (e) => {


        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            navigate("/");
        }
    }
    const clickAwayHandler = () => {
        //console.log("clickAwayHandler click, shareOpen: ", shareOpen, " cRCAR: ", veryRecentlyClickedAwayRef.current)
        if (shareOpen) {
            setShareOpen(false);
            veryRecentlyClickedAwayRef.current = true;
            setTimeout(() => { veryRecentlyClickedAwayRef.current = false }, 1)
        }


    }
    const shareClickHandler = () => {
        //console.log("shareClickHandler click, shareOpen: ", shareOpen, " cRCAR: ", veryRecentlyClickedAwayRef.current)
        if (shareOpen) {
            setShareOpen(false);
        } else if (!veryRecentlyClickedAwayRef.current) {
            setShareOpen(true);
            //@ts-ignore
            typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
            //@ts-ignore
            window.gtag('event', "opened_sharebox", {
                    'event_category': "user_interaction" 
            });
            
        }
        //await setShareOpen(prev=>!prev);
        //console.log(shareBoxRef.current)
        //if (shareBoxRef.current) shareBoxRef.current.focus()
    }
    
    
    
    useEffect(() => setShareOpenRef.current(false), []);


   

    return (
        <Container>
            {/* OBS NEXT LINK */}
            <BackButton
                aria-label={`${t("navBar.backAriaLabel")} ${t(backObj.title)}`}
                onClick={() => { navigate(backObj.url) }}
                tabIndex={0}
                data-testid="backbutton">
                <IconContainer >
                    <IconArrowLeft aria-hidden={true} />
                </IconContainer>
                <BackButtonText>
                    {t(backObj.title)}
                </BackButtonText>
            </BackButton>

            <AppTitle
                onClick={() => { navigate("/") }}
                onKeyDown={keyDownHandler}
                role="button"
                tabIndex={0}
            >TIDSROM</AppTitle>
            <BtnContainer>
               <TextSizeButton/>
                <ShareButton handleClick={shareClickHandler} />
                <LanguageButton />
            </BtnContainer>
            <ClickAwayListenerCustom onClickAway={clickAwayHandler}>
                <ClickAwayInnerContainer>                    
                    <ShareBox
                        pageUrl={pageUrl}
                        visible={shareOpen}
                        title={title}
                        description={description}      
                        //@ts-ignore                  
                        role="dialog"                        

                    />
                </ClickAwayInnerContainer>
            </ClickAwayListenerCustom>



        </Container >
    );
}

export default NavBar;