import { useState, useEffect } from 'react';


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default () => {
  const [windowDimensions, setWindowDimensions] = useState(typeof window=="undefined" ?
     {width: 1024, height: 768} : getWindowDimensions() /*getWindowDimensions(),*/
  );

  useEffect(() => {

    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
