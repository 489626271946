export const shortLangCode = (langCode) => {
    const SUPPORTED_LANGUAGES = ["en", "no"]
    const shortLangCode = langCode.substring(0, 2).toLowerCase();
    if(["nb", "nn"].includes(shortLangCode)){
        return "no"
    }
    if (!SUPPORTED_LANGUAGES.includes(shortLangCode)) {
        return "en";
    }
    return langCode.substring(0, 2);
}

/**
 * Parses a subset of markdown. 
 *  Examples: \*italic\*,
 *  \*\*bold\*\*
 * @param in_text 
 */
export const miniMarkdownParser = (in_text: string) => {
    
    //const Test = React.createElement("strong", {}, " Strong text");
    const F = "*"; // format symbol
    const SPLIT_CHR = "|";

    const output = [];
    const symbolStack = [];
    const contentStack = [];

    let withSplitChar = in_text.replace(/([^\*]+)/g, `${SPLIT_CHR}$1${SPLIT_CHR}`);

    // Remove redundant split characters
    if (withSplitChar[0] === SPLIT_CHR && withSplitChar[withSplitChar.length - 1] === SPLIT_CHR) {
        withSplitChar = withSplitChar.substring(1, withSplitChar.length - 1);
    }
    else if (withSplitChar[withSplitChar.length - 1] === SPLIT_CHR) {
        withSplitChar = withSplitChar.substring(0, withSplitChar.length - 1);
    }
    else if (withSplitChar[0] === SPLIT_CHR) {
        withSplitChar = withSplitChar.substring(1);
    }
    const splitArr = withSplitChar.split(SPLIT_CHR);

    /*
    console.log(withSplitChar);
    console.log(splitArr);
    console.log("INPUT: ", in_text)
    */

    const invertSymbol = (in_symbol) => {
        return in_symbol === F ? F + F : F;
    }

    const formatDict = {
        "*": {
            open: "<i>",
            close: "</i>",
            type: "i"
        },
        "**": {
            open: "<strong>",
            close: "</strong>",
            type: "strong"
        }
    }


    const tagCloser = () => {
       /*
        console.group("tagCloser");
        console.log("contentStack: ", contentStack);
        console.log("symbolStack: ", symbolStack);
        console.groupEnd()
        */
        const tmpSymbol = symbolStack.length ? symbolStack.pop() : undefined;
        /*
        const tmpContent = tmpSymbol ? {
            tag: formatDict[tmpSymbol].type,
            content: [contentStack.pop()]
        } : [contentStack.pop()];

        if (symbolStack.length === 0) {
            while (contentStack.length) {
                tmpContent.content.push(contentStack.pop())
            }

            output.push(tmpContent);
        } else {
            contentStack.push(tmpContent);
        }
        */
        return tmpSymbol;
    }

    const operations = (currentEl, idx = -1) => {
        //console.group("operations:\ncurrentEl: ", currentEl,"\nidx: ",idx);
        const symbolStackPeek = symbolStack[symbolStack.length - 1];

        // ***
        if (currentEl === F + F + F) {
            if (symbolStack.length === 0) {

                const nextClosingSymbol = splitArr[idx + 2];
                //console.log("nextClosingSymbol: ", nextClosingSymbol);

                // Does not know if opening tag should be strong or i 
                // if next symbol is ***. Prioritizes *
                if (nextClosingSymbol === F + F + F) {
                    symbolStack.push(F);
                    symbolStack.push(F + F);
                    splitArr[idx] = formatDict[F].open + formatDict[F + F].open;
                }
                else {
                    symbolStack.push(invertSymbol(nextClosingSymbol));
                    symbolStack.push(nextClosingSymbol);
                    splitArr[idx] = formatDict[invertSymbol(nextClosingSymbol)].open + formatDict[nextClosingSymbol].open;
                }
            }
            else if (symbolStack.length === 2) {
                const sPop1 = symbolStack.pop();
                const sPop2 = symbolStack.pop();
                /*
                output.push({
                    tag: formatDict[sPop1].type,
                    content: {
                        tag: formatDict[sPop2].type,
                        content: [contentStack.pop()]
                    }
                });
                */
                splitArr[idx] = formatDict[sPop1].close + formatDict[sPop2].close;
            }
            else {
                const tmpSymbol = tagCloser();
                const newSymbol = invertSymbol(tmpSymbol)
                symbolStack.push(newSymbol);

                splitArr[idx] = formatDict[tmpSymbol].close;
            }
        }

        // **
        else if (currentEl === F + F) {
            if (symbolStack.length === 0 || symbolStackPeek !== currentEl && symbolStack.length === 1) {
                symbolStack.push(F + F);
                splitArr[idx] = formatDict[F + F].open;
            }
            else if (symbolStackPeek === currentEl) {
                const tmpSymbol = tagCloser();
                splitArr[idx] = formatDict[tmpSymbol].close;
            }
        }

        // *
        else if (currentEl === F) {
            if (symbolStack.length === 0 || symbolStackPeek !== currentEl) {
                symbolStack.push(F);
                splitArr[idx] = formatDict[F].open;
            }
            else if (symbolStackPeek === currentEl) {
                const tmpSymbol = tagCloser();
                splitArr[idx] = formatDict[tmpSymbol].close;
            }
        }
        /*
        else {
            if (symbolStack.length === 0) {

                
                output.push([currentEl])
            }
            else {
                contentStack.push(currentEl);
            }
            
        }*/
        /*
        console.log(idx);
        console.log(splitArr);
        console.log("stack: ", symbolStack);
        console.log("contentStack: ", contentStack);
        console.groupEnd();
        */
    }

    for (let i = 0; i < splitArr.length; i++) {
        operations(splitArr[i], i);
    }
    
    /*
    if (contentStack.length) {
        tagCloser();
    }
    */

    while (symbolStack.length){
        splitArr.push(formatDict[symbolStack.pop()].close)
    }
    
    /*
    console.log("--------------------")
    console.log(withSplitChar)
    console.log(splitArr);
    console.log("output:")
    console.log(output);
    //operations("_")
    console.log("\n")
    //return output;
    */
    return splitArr;
}