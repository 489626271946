import React, {useEffect, useRef, useState} from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Settings } from "../../../config/Settings";

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    InstapaperShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,

} from "react-share";
import shareImage from "../../../images/bg/bgimg.png";
import IconWebShare from "../../modules/IconWebShare/iconWebShare";

const Container = styled.div<ContainerProps>`
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 20px;  
    background-color: #F0F0F0;
    z-index: 100;    
    box-shadow: 0 0 50px 1px rgba(0,0,0,0.2);      
    //gap: 15px;
    top: 100px;    
    right: ${props => props.visible ? "calc(-1 * var(--marginLeftSm))" : "-150px"};
   
    transform: translateX(0);
    width: 75px;
    max-height: 8rem;
    min-height: ${props =>props.showWebShare ? "200px" : "150px"};
    flex-direction: column;
    border-radius: 5px 0 0 5px;
    transition: right 1s ease-in-out;     
        
    @media only screen and (min-width: 1024px){
        right: ${props => props.visible ? "calc(-1 * var(--marginLeftLg))" : "-150px"};
    }

`;


const buttonsShared = ({ theme, myProp }) => css`
    position: relative;    
    &:hover {
        opacity: 0.7;
        transform: scale(var(--hoverScale));
    }        

    & :focus{  
        outline: none;     
        & circle{
                stroke: black;
                stroke-width: 3px;                
            }
    }
`

const CustomFaceBooksSharebutton = styled(FacebookShareButton)`
    ${buttonsShared}
`;

const CustomTwitterSharebutton = styled(TwitterShareButton)`
    ${buttonsShared}
`;

const CustomPinterestShareButton = styled(PinterestShareButton)`
    ${buttonsShared}
`;

const WebShareButton = styled.button`
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background-color: #a9a9a9;//#1834b1;
    cursor: pointer;    
    position: relative;
    flex-shrink: 0;

    &:hover {
        opacity: 0.7;
        transform: scale(var(--hoverScale));
    }        

    & :focus{  
        outline: 3px black;     
        
    }
`;

interface ContainerProps {
    visible: boolean;
    showWebShare: boolean;
}

interface ShareBoxProps {
    pageUrl: URL;
    visible: boolean;
    title: string;
    description: string;
}

const ShareBox: React.FC<ShareBoxProps> = ({
    pageUrl,
    visible,
    title,
    description
}) => {
    const staticPageUrl = Settings.PAGE_URL;
    const iconsize = 32;
    const { t } = useTranslation("common");
    const [showWebShare, setShowWebshare] = useState(false);
    const showWebShareRef = useRef(setShowWebshare);
    const handleWebShare = () =>{
        if ( typeof navigator  !== "undefined" && showWebShare) {
            navigator.share({
              title: title,
              text: description,
              url: pageUrl.toString(),
            })
              .then(() => console.log('Successful share'))
              .catch((error) => console.log('Error sharing', error));
          }
    }

    useEffect(()=>{
        if(typeof navigator  !== "undefined" && !!navigator.share){
            showWebShareRef.current(true);
        }
    },[])

    return (
        <Container
            data-testid="sharebox"
            visible={visible}
            showWebShare={showWebShare} >
            {/*  @ts-ignore */}
            {showWebShare &&   
            <WebShareButton 
                onClick={handleWebShare}
                tabIndex={visible ? 0 : -1}
                aria-label={t("shareBox.webshare")}
                >
                <IconWebShare />
            </WebShareButton>}

            {/*  @ts-ignore */}
            <CustomFaceBooksSharebutton
                url={/*staticPageUrl*/pageUrl.toString()}
                aria-label={t("shareBox.facebook")}
                tabIndex={visible ? 0 : -1}
            >
                <FacebookIcon size={iconsize} round={true} />
            </CustomFaceBooksSharebutton>
            {/*  @ts-ignore */}
            {false && <CustomPinterestShareButton
                media={staticPageUrl + shareImage.toString()}
                url={/*staticPageUrl*/pageUrl.toString()}
                aria-label={t("shareBox.pinterest")}
                tabIndex={visible ? 0 : -1}
            >
                <PinterestIcon size={iconsize} round={true} />
            </CustomPinterestShareButton>}
            {/*  @ts-ignore */}
            <CustomTwitterSharebutton
                url={/*staticPageUrl*/pageUrl.toString()}
                aria-label={t("shareBox.twitter")}
                tabIndex={visible ? 0 : -1}
            >
                <TwitterIcon size={iconsize} round={true} />
            </CustomTwitterSharebutton>

        </Container>
    );
}

export default ShareBox;