import React, { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Store } from "../../Store/store";

const OldBrowserInfo = styled.div<OldBrowserInfoProps>`
    display: ${props => props.warningVisible ? "block" : "none"};
    position: fixed;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    background: #e7e7e7;
    border-radius: 0 0 1em 1em;    
    padding: 3em 2em 1em;   
    max-width: 80vw; 
    border: black solid 1.5px;
    border-top: none;
    //opacity: ${props => props.warningVisible ? 1.0 : 0};
    //transition: opacity 0.5s ease-in, display 0.5s;
    cursor: pointer;
    opacity: 0.7;
    z-index: 100;

    & i{
        display: inline-block;
        text-align: center;
        width: 100%;
        font-size: 0.75em;
    }     

    @supports (display:grid) and (font-stretch:expanded) and (align-items: first baseline){
        display: none;
    }
`;


interface OldBrowserInfoProps {
    warningVisible: boolean;
}

const OldBrowserWarning = () => {    
    const { state, dispatch } = useContext(Store);    
    const {t} = useTranslation("common");
    return (
        <OldBrowserInfo
            warningVisible={state.data.outDatedBrowserWarning}
            onClick={() => {                
                dispatch({
                    type: "SET_DATA_OUTDATEDBROWSERWARNING",
                    payload: false
                })
                
            }}
        >{t("oldBrowserWarning.warningMessage")}<br /><br /> <i>{t("oldBrowserWarning.closeInstructions")}</i>
        </OldBrowserInfo>
    )
}

export default OldBrowserWarning;